import {Box, Fade} from "@mui/material";
import React from "react";
import {useAppState} from "../app/appContext";
import {NoAccessPlaceHolderContent} from "./NoAccessPlaceHolderContent";
import {NoAccessPlaceHolderText} from "./NoAccessPlaceHolderText";
import {NoAccessPlaceHolderFooter} from "./NoAccessPlaceHolderFooter";

export const NoAccessPlaceHolder = ({show}) => {
    const {appId, user} = useAppState()
    const userHasNoAccess = () => {
        if(user.appAccess.apps) {
            console.debug(user.appAccess)
            const museumApps = user.appAccess.museums.map(museum => museum.applications)
                .reduce((a, b) => [...a, ...b], [])

            const allApps = [...user.appAccess.apps, ...museumApps]
            return !allApps.find(app => app.id === appId)
        } else {
            return true
        }

    }

    if (user?.appAccess && show && userHasNoAccess()) {
        return (
            <Fade
                in={true}
                timeout={{
                    enter: 500,
                    leave: 500
                }}
                style={{
                    transitionDelay: "1000ms"
                }}
            >
                <Box
                    sx={{
                        backgroundImage: "url(https://ems.dimu.org/image/019EE5EDQsji2?dimension=3072)",
                    }}
                >
                    <NoAccessPlaceHolderContent>
                        <NoAccessPlaceHolderText />
                        <NoAccessPlaceHolderFooter />
                    </NoAccessPlaceHolderContent>
                </Box>
            </Fade>
        )
    } else {
        return null
    }

}