import {Box} from "@mui/material";
import {Illustration} from "./Illustration";

const dmsHost = 'https://ems.dimu.org'

export const EkulturPlaceHolderImage = () => {
    const maskSvg = `${dmsHost}/image/019EE5EDVL335?mediaType=image/svg%2Bxml`
    const buttonIllustration = `${dmsHost}/image/019EE5EDVL37R?dimension=256&mediaType=image/png`
    const ticketIllustration = `${dmsHost}/image/019EE5EDVL37Q?dimension=256&mediaType=image/png`
    const graphIllustration = `${dmsHost}/image/019EE5EDVL37S?dimension=256&mediaType=image/png`
    const pageViewsIllustration = `${dmsHost}/image/019EE5EDVL37T?dimension=256&mediaType=image/png`

    return (
        <Box
            sx={{
                position: "relative",
            }}
        >
            <Box
                sx={{
                    maskImage: `url(${maskSvg})`,
                    maskRepeat: "no-repeat",

                }}
            >
                <img
                    width={411}
                    height={500}
                    src={`${dmsHost}/image/019EE5EDVL332?dimension=512`}
                    alt={"Girl with computer"}
                />

            </Box>
            <Illustration
                top={39}
                left={126}
                src={pageViewsIllustration}
                alt={"page-views"}
                width={200}
                height={88.92}
                boxShadow={`
                    0px 7.43243px 10.1351px -4.72973px rgba(0, 0, 0, 0.1),
                    0px 16.2162px 25.6757px 2.02703px rgba(0, 0, 0, 0.04),
                    0px 6.08108px 31.0811px 5.40541px rgba(0, 0, 0, 0.02);
                `}
                transitionDelay={"2000ms"}
            />
            <Illustration
                top={250}
                left={-110}
                src={ticketIllustration}
                alt={"tickets illustration"}
                width={200}
                height={88.92}
                boxShadow={`
                    0px 7.43243px 10.1351px -4.72973px rgba(0, 0, 0, 0.1),
                    0px 16.2162px 25.6757px 2.02703px rgba(0, 0, 0, 0.04),
                    0px 6.08108px 31.0811px 5.40541px rgba(0, 0, 0, 0.02);
                `}
                transitionDelay={"2500ms"}
            />
            <Illustration
                top={246}
                left={289}
                src={graphIllustration}
                alt={"graph illustration"}
                width={94.27}
                height={125}
                boxShadow={`
                    0px 7.43243px 10.1351px -4.72973px rgba(0, 0, 0, 0.1),
                    0px 16.2162px 25.6757px 2.02703px rgba(0, 0, 0, 0.04),
                    0px 6.08108px 31.0811px 5.40541px rgba(0, 0, 0, 0.02);
                `}
                transitionDelay={"1500ms"}
            />
            <Illustration
                top={373}
                left={155}
                src={buttonIllustration}
                alt={"New content button illustration"}
                width={129.27}
                height={49.55}
                boxShadow={`
                    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                    0px 6px 10px rgba(0, 0, 0, 0.14),
                    0px 1px 18px rgba(0, 0, 0, 0.12);
                `}
                backgroundColor={"#3E78BB"}
                borderRadius={"738.029px"}
                transitionDelay={"3000ms"}
            />
        </Box>
    )
}