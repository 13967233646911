import {Avatar, Button, ButtonBase, Stack, Typography} from "@mui/material";
import {useGetUser} from "./useGetUser";
import {useGetApplications} from "../application/useGetApplications";
import {AccountBox} from "@mui/icons-material";
import {useAppTranslation} from "../app/appContext";

export const ProfileInfo = () => {
    const apps = useGetApplications()
    const t = useAppTranslation()

    const clickHandler = () => {
        const adminApp = apps["0f99554d-4d53-4ce6-ada5-6189f3abdf52"];
        window.location.href = `${adminApp?.url}/profile/`
    }
    const user = useGetUser()
    return (
        <Stack
            alignItems={"center"}
            component={ButtonBase}
            sx={{
                width: "100%"
            }}
            onClick={clickHandler}
        >
            <Avatar
                alt={user.name}
                src={user.imageUrl}
                sx={{
                    width: theme => theme.spacing(9),
                    height: theme => theme.spacing(9),
                    marginTop: theme => theme.spacing(2),
                }}
            />
            <Typography
                variant="h6"
            >
                {user.name}
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
            >
                {user.email}
            </Typography>
            <Button
                startIcon={<AccountBox />}
            >
                {t('editProfileButton', "Rediger profil")}
            </Button>
        </Stack>
    )
}
