import {AppBar, Toolbar} from "@mui/material";

export const EkulturAppBar = ({authenticated, children}) => {
    return (
        <AppBar
            sx={{
                backgroundColor: theme => authenticated ? theme.palette.primary.main :  "#FFFFFF",
                color: authenticated ? "default" : "black",
                transition: theme => theme.transitions.create(['background-color', 'color', 'transform'], {
                    duration: theme.transitions.duration.standard
                }),
            }}
        >
            <Toolbar>
                {children}
            </Toolbar>
        </AppBar>
    )
}