import {Box, Button, Unstable_Grid2 as Grid} from "@mui/material";
import {kitFetch} from "@ekultur/fetch";
import {ApplicationIcon} from "./ApplicationIcon";

export const ApplicationButton = ({app}) => {

    const openApp = url => {
        if(app.openInNewTab || app.open_in_new_tab) {
            window.open(url, `_${app.title}`, 'noopener')
        } else {
            window.open(url, "_self")
        }
    }

    const clickHandler = () => {
        if(app.directUrl) {
            openApp(app.url)
        } else {
            kitFetch(app.url)
                .then(json => {
                    openApp(json.url)
                })
        }
    }


    return (
        <Grid
            xs={6}
        >

            <Button
                size={"small"}
                onClick={clickHandler}
                sx={{
                    padding: 0,
                    maxWidth: "100%",
                    '&.header-MuiButton-text': {
                        textTransform: "none !important",
                        color: "rgba(0, 0, 0, 0.87)",
                        fontWeight: 400,
                        fontSize: "0.875rem",
                        letterSpacing: 0,
                    }
                }}
            >
                    <ApplicationIcon
                        serviceIcon={app.serviceIcon}
                        serviceApplication={app.serviceApplication}
                        primaryColor={app.theme.primaryColor}
                        initials={app.initials}
                        title={app.title}
                        size={24}
                    />
                    <Box
                        sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            ml: 0.5
                        }}
                    >
                        {app.displayTitle ? app.displayTitle : app.title}
                    </Box>
            </Button>
        </Grid>
    )
}