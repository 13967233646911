import {Stack, Typography} from "@mui/material";
import {useGetApplications} from "../application/useGetApplications";
import {useAppState} from "../app/appContext";
import {ApplicationIcon} from "../application/ApplicationIcon";

export const ApplicationInfo = () => {
    const apps = useGetApplications()
    const {
        appId,
        version,
        buildDate,
    } = useAppState()

    const app = apps[appId]

    console.debug({app})

    return (
        <Stack
            alignItems={"center"}
        >
            <ApplicationIcon
                serviceIcon={app.serviceIcon}
                serviceApplication={app.serviceApplication}
                primaryColor={app.theme.primaryColor}
                initials={app.initials}
                title={app.title}
            />
            <Typography
                variant="body2"
                color="textSecondary"
            >
                {app.title}
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
            >
                {version} {buildDate}
            </Typography>
        </Stack>
    )
}